var equalheight = function (container) {
    if (jQuery(window).width() > 767) {
        var currentTallest = 0,
            currentRowStart = 0,
            rowDivs = [],
            jQueryel,
            topPosition = 0;
        jQuery(container).each(function () {
            jQueryel = jQuery(this);
            jQuery(jQueryel).height('auto');
            topPostion = jQueryel.position().top;

            if (currentRowStart != topPostion) {
                for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
                    rowDivs[currentDiv].height(currentTallest);
                }
                rowDivs.length = 0; // empty the array
                currentRowStart = topPostion;
                currentTallest = jQueryel.height();
                rowDivs.push(jQueryel);
            } else {
                rowDivs.push(jQueryel);
                currentTallest = (currentTallest < jQueryel.height()) ? (jQueryel.height()) : (currentTallest);
            }
            for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
                rowDivs[currentDiv].height(currentTallest);
            }
        });
    } else {
        jQuery(container).height('auto');
    }
};

var fullheight = function () {
 	jQuery(".banner-section .slider-for").height(jQuery(window).height());
	jQuery(".banner-section .slider-for .slick-slide").height(jQuery(window).height());
    equalheight('.site-footer .col-sm-6');
    equalheight('.slider-nav .col-sm-4');

    var headerh = jQuery(".site-header").outerHeight();
    jQuery(".banner-content").css('margin-top',headerh / 2);
};

jQuery(document).ready(function() {
    jQuery('.slider-for').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      autoplay: true,
      adaptiveHeight: false,
	  asNavFor: '.slider-nav'
    });


    jQuery('.slider-nav').slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      asNavFor: '.slider-for',
      dots: false,
      arrows: false,
      centerMode: false,
      focusOnSelect: true,
      autoplay: true

    });

	jQuery('.wp-tiles-tile a').attr("rel","gallery");

    jQuery('.wp-tiles-tile a').fancybox({
        helpers: {
            overlay: {
              locked: false
            }
        }
    });

    jQuery(".mobile-menu a").on("click touchstart", function (event) {
        event.preventDefault();
		event.stopPropagation();
		jQuery("body").toggleClass("open-menu");
    });

    jQuery(document).on('click', function () {
        jQuery('body').removeClass('open-menu');
    });

     jQuery(".site-header .header-right").on("click touchstart", function (event) {
		event.stopPropagation();
    });

    /*jQuery(".wp-tiles-grid").slick({
        dots: false,
        autoplay: false,
        arrows: true
    });*/

    var nav = jQuery('.site-header');
    jQuery(window).scroll(function () {
        if (jQuery(this).scrollTop() > 1) {
            nav.addClass("navsticky");
        } else {
            nav.removeClass("navsticky");
        }
    });

    jQuery(".touch .main-navigation ul li > a").on("click touchstart",function(event){

	   if(!jQuery(this).parent().hasClass("open") && jQuery(this).parent().has("ul").length > 0)
	   {
		event.preventDefault();
		event.stopPropagation();
		jQuery(this).parent().addClass("open").siblings().removeClass("open");
	   }
	  });

	  jQuery(".navigation ul li.menu-item-has-children").prepend("<span class='plus'></span>");

    fullheight();
});

jQuery(window).load(function () {
    fullheight();
});

jQuery(window).resize(function () {
    fullheight();
});
